import { FC } from 'react';
import cn from 'classnames';

interface HeadingProps {
  className?: string;
}

const Heading: FC<HeadingProps> = ({ className, children }) => (
  <h2 className={cn('text-4xl text-center', className)}>{children}</h2>
);

export default Heading;
