import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { getClientConfig } from "../config";
import { StepRouteType, StepType } from "../config/types";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useStep = (route: StepRouteType) => {
  const client = useAppSelector((state: RootState) => state.enquiry.client);

  const config = getClientConfig(client);
  const steps = config.steps as StepType[];
  return steps.find((step) => step.route === route);
};
