const steps = [
  {
    name: 'Where?',
    route: '/',
    title: 'Where would you like to live?',
    stepIndex: 0,
  },
  {
    name: 'When?',
    route: '/when',
    title: 'When would you like to move?',
    stepIndex: 1,
  },
  {
    name: 'When?',
    route: '/how-long',
    title: 'How long are you staying?',
    stepIndex: 1,
  },
  {
    name: 'Who?',
    route: '/who',
    title: "Who's moving in?",
    stepIndex: 2,
  },
  {
    name: 'Budget?',
    route: '/budget',
    title: 'What’s your monthly budget?',
    stepIndex: 3,
  },
  {
    name: 'Units',
    route: '/offers',
    title: 'This is what we have available for you...',
    stepIndex: 4,
  },
  {
    name: 'Book a viewing!',
    route: '/viewing',
    title: 'How would you like to do the viewing?',
    stepIndex: 5,
  },
  {
    name: 'Book a viewing!',
    route: '/done',
    title: 'Done',
    stepIndex: 5,
  },
];

const budgetScale = {
  initialMin: 2000,
  initialMax: 3000,
  min: 1000,
  max: 5000,
  step: 200,
};

const availabilityStep = true;

const test = {
  petSection: true,
  steps,
  budgetScale,
  availabilityStep,
};

const prod = {
  petSection: true,
  steps,
  budgetScale,
  availabilityStep,
};

const deltas = {
  test,
  prod,
};

module.exports = deltas;
