import { useEffect, MouseEvent } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';

import { RootState } from './app/store';

import Where from './components/pages/Where';
import When from './components/pages/When';
import HowLong from './components/pages/HowLong';
import Who from './components/pages/Who';
import Budget from './components/pages/Budget';
import Offers from './components/pages/Offers';
import Viewing from './components/pages/Viewing';
import Calendly from './components/pages/Calendly';
import Done from './components/pages/Done';
import { useAppSelector } from './app/hooks';
import Modal from './components/ui/Modal';
import ReactDOM from 'react-dom';

function App() {
  const navigate = useNavigate();

  const destination = useAppSelector((state: RootState) => {
    const { where } = state;
    return where.chosenDestination;
  });

  const rootRl = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    const { rootEl } = enquiry;
    return rootEl;
  });

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  useEffect(() => {
    if (!destination) {
      navigate('/');
    }
  }, [destination, navigate]);

  const handleBackgroundClick = (e: MouseEvent) => {
    e.stopPropagation();
    const { target } = e;
    // @ts-ignore
    const { id } = target;
    if (id === 'enquiry-tool-rerum-inner') {
      hide();
    }
  };

  const hide = () => {
    if (rootRl !== null) {
      const element = document.getElementById(rootRl);
      if (element) {
        // @ts-ignore
        ReactDOM.unmountComponentAtNode(element);
      }
    }
  };

  const mainClass = `enquiry-tool-rerum-${client} ${client}theme`;

  return (
    <div id="enquiry-tool-rerum" className={mainClass} onClick={handleBackgroundClick}>
      <div
        className="fixed inset-0 bg-black/40 flex h-full w-full z-600 lg:p-10"
        id="enquiry-tool-rerum-inner"
      >
        <div className="bg-white flex h-full w-full lg:rounded-lg relative shadow-lg">
          <Routes>
            <Route path="/" element={<Where />} />
            <Route path="/when" element={<When />} />
            <Route path="/how-long" element={<HowLong />} />
            <Route path="/who" element={<Who />} />
            <Route path="/budget" element={<Budget />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/viewing" element={<Viewing />} />
            <Route path="/calendly" element={<Calendly />} />
            <Route path="/done" element={<Done />} />
          </Routes>
          <button
            className="absolute top-3 right-3 h-5 w-5"
            onClick={() => hide()}
          >
            <XIcon className="text-slate-600" />
          </button>
          <Modal />
        </div>
      </div>
    </div>
  );
}

export default App;
