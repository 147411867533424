import { FC } from 'react';

import { useStep } from '../../../app/hooks';

import Steps from '../Steps';
import Heading from '../Heading';
import Spinner from '../Spinner';

interface OffersLayoutProps {
  isLoading?: boolean;
}

const OffersLayout: FC<OffersLayoutProps> = ({ isLoading, children }) => {
  const currentStep = useStep('/offers');

  return (
    <div className="flex flex-col items-center px-4 h-full w-full overflow-y-auto mx-auto lg:container lg:px-40">
      <Steps stepIndex={currentStep?.stepIndex} />
      {isLoading && (
        <>
          <Heading />
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        </>
      )}
      {!isLoading && children}
    </div>
  );
};

export default OffersLayout;
