const steps = [
  {
    name: 'Where?',
    route: '/',
    title: 'In which corner of Brussels would you like to live?',
    stepIndex: 0,
  },
  {
    name: 'When?',
    route: '/when',
    title: 'When would you like to move in?',
    stepIndex: 1,
    reverseLayout: true,
  },
  {
    name: 'When?',
    route: '/how-long',
    title: 'How long do you plan to stay with us?',
    stepIndex: 1,
  },
  {
    name: 'Who?',
    route: '/who',
    title: "Who's moving in?",
    stepIndex: 2,
    reverseLayout: true,
    contentClassName: 'flex-col items-center',
  },
  {
    name: 'Budget?',
    route: '/budget',
    title: 'What is your monthly budget?',
    stepIndex: 3,
  },
  {
    name: 'Book a viewing!',
    route: '/viewing',
    title: 'How would you like to meet us?',
    stepIndex: 4,
    reverseLayout: true,
  },
  {
    name: 'Book a viewing!',
    route: '/done',
    title: 'Done',
    stepIndex: 4,
  },
];

const test = {
  steps,
  petSection: false,
  unit: 'ghost_unit',
  rateId: 1,
  offerId: '123',
  chessLayout: true,
  fullBackgroundImage: false,
  howLongDurations: ['3 months', '4 months', '5 months', '6+ months'],
  budgetScale: {
    initialMin: 900,
    initialMax: 1500,
    min: 700,
    max: 2000,
    step: 100,
  },
  currencySymbol: '€',
  budgetBlurb:
    'OUR PRICES INCLUDE FURNITURE, UTILITY BILLS, CLEANING, COFFEE GRAINS, SOAPS, DETERGENT AND MORE!',
  viewingOptions: ['Call', 'Video Call'],
};

const prod = {
  steps,
  petSection: false,
  unit: 'ghost_unit',
  rateId: 1,
  offerId: '123',
  fullBackgroundImage: false,
  howLongDurations: ['3 months', '4 months', '5 months', '6+ months'],
  budgetScale: {
    initialMin: 900,
    initialMax: 1500,
    min: 700,
    max: 2000,
    step: 100,
  },
  currencySymbol: '€',
  budgetBlurb:
    'OUR PRICES INCLUDE FURNITURE, UTILITY BILLS, CLEANING, COFFEE GRAINS, SOAPS, DETERGENT AND MORE!',
  viewingOptions: ['Call', 'Video Call'],
};

const deltas = {
  test,
  prod,
};
module.exports = deltas;
