const RESET_ALL = "reset/all";
const SET_ROOT_EL = "set/rootel";

const SET_CLIENT = "set/client";
const resetAll = () => {
  return {
    type: RESET_ALL,
  };
};

const SET_OFFERS = "set/manual/offers";

const setRootEl = (el: string) => {
  return {
    type: SET_ROOT_EL,
    payload: el,
  };
};

const setClient = (client: string) => {
  return {
    type: SET_CLIENT,
    payload: client,
  };
};

const setManualOffers = (offers: [{
  buildingName: string;
  name: any;
  id: any;
  rateAvailability: { rateIdentifier: any }
}]) => {
  return {
    type: SET_OFFERS,
    payload: offers,
  };
};

export { resetAll, RESET_ALL, setRootEl, SET_ROOT_EL, SET_CLIENT, setClient, SET_OFFERS, setManualOffers };
