import { FC } from 'react';
import cn from 'classnames';
import { OfferImageType } from '../../../service/types';

type OfferInnerImageProps = {
  url: string;
  name: string;
  index: string | number | null | undefined;
  pictureIndex: number;
  onClick: (picture: OfferImageType) => void; // eslint-disable-line
  thirdParty: boolean;
  picture: OfferImageType;
};

const cloudinaryFetchUrl =
  'https://res.cloudinary.com/resharmonics/image/fetch/w_300/v1650526239/';

const OfferInnerImage: FC<OfferInnerImageProps> = ({
  url,
  name,
  index,
  pictureIndex,
  onClick,
  thirdParty,
  picture,
}) => {
  const setModal = () => {
    if (!thirdParty) {
      onClick(picture);
    }
  };
  const pictureClass = cn({
    'cursor-pointer object-cover h-full w-full rounded-md': !thirdParty,
    hidden: index !== pictureIndex,
  });

  const finalUrl = `${cloudinaryFetchUrl}https:${url}`;

  return (
    <picture key={index} className={pictureClass} onClick={setModal}>
      <source
        srcSet={finalUrl}
        className={cn('object-cover h-full w-full rounded-md', {
          hidden: index !== pictureIndex,
        })}
      />
      <img
        src={`${process.env.PUBLIC_URL}/offers-images/apartment.jpeg`}
        alt={name}
        className={cn('object-cover h-full w-full rounded-md', {
          hidden: index !== pictureIndex,
        })}
        loading="lazy"
      />
    </picture>
  );
};

export default OfferInnerImage;
