import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { getClient } from "../service/api";

type TypeValue = {
  type: string;
  value: string;
};

type CalendlyEvent = {
  event: string;
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
};

/*
private Long areaId;
	private String approximateLengthOfStay;
	private LocalDate startDate;
	private LocalDate endDate;
	private Boolean pets;
	private Integer guests;
	private String notes;
	private List<Properties> deltas;
 */

type BookingLead = {
  areaId: number;
  unitName: string;
  rateCode: string;
  startDate: string;
  endDate: string;
  pets: boolean;
  guests: number;
  notes: string;
  deltas: TypeValue[];
  who: string;
  howLong: string;
  budget: string;
};

const createLead = createAsyncThunk(
  "lead/generate",
  async (datas: CalendlyEvent, { getState }) => {
    try {
      const state = getState();
      const { payload } = datas;
      const { event, invitee } = payload;
      // @ts-ignore
      const { enquiry, offers, where } = state; // eslint-disable-line
      const { client } = enquiry;
      const currentOffers = offers.offers;
      const { from, duration, withPets, offerId, rateCode, guests, whoIsStaying, budget } = enquiry;
      const { min, max } = budget;
      const offer = currentOffers.find((off: any) => {
        return (
          off.id === offerId && off.rateAvailability.rateIdentifier === rateCode
        );
      });
      const { chosenDestination } = where;
      const areaId = chosenDestination?.identifier;
      const startDate = new Date(from * 1000);
      let endDate = new Date(startDate.getTime());
      switch (duration) {
        case "3-5 months": {
          endDate.setMonth(endDate.getMonth() + 3);
          break;
        }
        case "6-9 months": {
          endDate.setMonth(endDate.getMonth() + 6);
          break;
        }
        case "10-12 months": {
          endDate.setMonth(endDate.getMonth() + 10);
          break;
        }
        default:

      }

      const guestsCount = guests ? guests : 1;
      const deltas = [];
      const calendlyType: TypeValue = {
        type: "calendly",
        value: "true",
      };
      const calendlyEventUrl: TypeValue = {
        type: "eventUrl",
        value: event?.uri,
      };
      const calendlyInviteeUrl: TypeValue = {
        type: "inviteeUrl",
        value: invitee?.uri,
      };
      const notes = `Who is staying: ${whoIsStaying} \n`;
      deltas.push(calendlyType, calendlyEventUrl, calendlyInviteeUrl);
      const lead: BookingLead = {
        budget: `${min} - ${max}`,
        howLong: duration,
        who: whoIsStaying,
        areaId,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        pets: withPets,
        guests: guestsCount,
        notes,
        deltas,
        unitName: offer.name,
        rateCode
      };
      const apiClient = getClient(client);
      const res = await apiClient.post("lead", lead);
      const { data } = res;
      if (data.results !== undefined) {
        return data.results;
      }
    } catch (e) {
      console.log({e});
      return {
        success: false,
      };
    }
  }
);

export { createLead };
