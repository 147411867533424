import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_ALL, SET_CLIENT, SET_ROOT_EL } from "../actions";
import { OfferImageType } from "../../service/types";

export type BudgetType = { min: number; max: number };
export type ViewingType = "Call" | "In person" | "Video Call";

export interface EnquiryState {
  destination: string | null;
  from: string | null | number;
  duration: string | null;
  isSingle: boolean | null;
  withPets: boolean | null;
  budget: BudgetType | null;
  offerId: string | number | null;
  rateCode: string | null;
  viewingType: ViewingType | null;
  fullScreenImage: string | null;
  rootEl: string | null;
  client: string | null;
  guests: number | null;
  whoIsStaying: null | string;
  images: OfferImageType[];
}

const initialState: EnquiryState = {
  destination: null,
  from: null,
  duration: null,
  isSingle: null,
  withPets: null,
  budget: null,
  offerId: null,
  viewingType: null,
  rateCode: null,
  fullScreenImage: null,
  rootEl: null,
  client: null,
  guests: null,
  whoIsStaying: null,
  images: [],
};

export const enquirySlice = createSlice({
  name: "enguiry",
  initialState,
  reducers: {
    setDestination: (state: EnquiryState, action: PayloadAction<string>) => {
      state.destination = action.payload;
    },
    setFrom: (state: EnquiryState, action: PayloadAction<number>) => {
      state.from = action.payload;
    },
    setDuration: (state: EnquiryState, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
    setIsSingle: (state: EnquiryState, action: PayloadAction<boolean>) => {
      state.isSingle = action.payload;
    },
    setWithPets: (
      state: EnquiryState,
      action: PayloadAction<boolean | null>
    ) => {
      state.withPets = action.payload;
    },
    setBudget: (state: EnquiryState, action: PayloadAction<BudgetType>) => {
      state.budget = action.payload;
    },
    setOfferId: (
      state: EnquiryState,
      action: PayloadAction<string | number>
    ) => {
      state.offerId = action.payload;
    },
    setViewingType: (state: EnquiryState, action: PayloadAction<string>) => {
      state.viewingType = action.payload as ViewingType;
    },
    setRateCode: (state: EnquiryState, action: PayloadAction<string>) => {
      state.rateCode = action.payload;
    },
    setFullScreenImage: (
      state: EnquiryState,
      action: PayloadAction<string | null>
    ) => {
      state.fullScreenImage = action.payload;
    },
    setImages: (
      state: EnquiryState,
      action: PayloadAction<OfferImageType[]>
    ) => {
      state.images = action.payload;
    },
    setGuests: (state: EnquiryState, action: PayloadAction<number | null>) => {
      state.guests = action.payload;
    },
    setWhoIsStaying: (
      state: EnquiryState,
      action: PayloadAction<string | null>
    ) => {
      state.whoIsStaying = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_ALL, (state) => {
      return {
        ...initialState,
        rootEl: state.rootEl,
        client: state.client,
      };
    });
    builder.addCase(SET_ROOT_EL, (state, action: any) => {
      return {
        ...state,
        rootEl: action.payload,
      };
    });
    builder.addCase(SET_CLIENT, (state, action: any) => {
      return {
        ...state,
        client: action.payload,
      };
    });
  },
});

export const {
  setFrom,
  setDuration,
  setIsSingle,
  setWithPets,
  setBudget,
  setOfferId,
  setViewingType,
  setRateCode,
  setFullScreenImage,
  setGuests,
  setWhoIsStaying,
  setImages,
} = enquirySlice.actions;

export default enquirySlice.reducer;
