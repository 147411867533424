import { FC } from 'react';

import { OfferImageType } from '../../../service/types';
import OfferInnerImage from './OfferInnerImage';

type OfferImageProps = {
  index: string | number | null | undefined;
  pictureIndex: number;
  onClick: (image: OfferImageType) => void; // eslint-disable-line
  picture: OfferImageType;
};

const OfferImage: FC<OfferImageProps> = ({
  index,
  pictureIndex,
  onClick,
  picture,
}) => {
  const { thirdPartyUrl, url, name } = picture;

  if (thirdPartyUrl != null && thirdPartyUrl !== '') {
    return (
      <a
        target="_blank"
        href={thirdPartyUrl}
        rel="noreferrer"
        className="object-cover h-full w-full rounded-md"
      >
        <OfferInnerImage
          picture={picture}
          url={url}
          name={name}
          index={index}
          pictureIndex={pictureIndex}
          onClick={onClick}
          thirdParty={true}
        />
      </a>
    );
  }

  return (
    <OfferInnerImage
      picture={picture}
      url={url}
      name={name}
      index={index}
      pictureIndex={pictureIndex}
      onClick={onClick}
      thirdParty={false}
    />
  );
};

export default OfferImage;
