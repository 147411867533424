import cn from 'classnames';
import { FC } from 'react';

import Steps from '../Steps';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { getClientConfig } from '../../../config';
import FullOrPartialLayout from '../FullOrPartialLayout';
import { StepType } from '../../../config/types';

interface AppLayoutProps {
  image: string;
  step?: StepType;
}

const AppLayout: FC<AppLayoutProps> = ({ image, step, children }) => {
  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const config = getClientConfig(client);
  const { fullBackgroundImage } = config;

  const wrapperClass = cn('flex h-full w-full', {
    relative: fullBackgroundImage,
    'flex-row-reverse': step?.reverseLayout,
  });

  return (
    <div className={wrapperClass}>
      <FullOrPartialLayout
        client={client}
        image={image}
        fullBackgroundImage={fullBackgroundImage}
      />
      <div className="flex flex-col flex-1 h-full z-10">
        <Steps stepIndex={step?.stepIndex} className="px-4" />
        <div className="flex-grow overflow-y-auto">
          <div className="flex flex-col items-center justify-center px-10 py-4 min-h-full">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
