import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { useAppDispatch, useAppSelector, useStep } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {
  setGuests,
  setWhoIsStaying,
  setWithPets,
} from '../../../features/enquiry/enquirySlice';

import { getClientConfig } from '../../../config';

import AppLayout from '../../ui/AppLayout';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';

const WhoPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentStep = useStep('/who');

  const withPets = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.withPets;
  });

  const guestCount = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.guests;
  });

  const client = useAppSelector((state: RootState) => {
    const { enquiry } = state;
    return enquiry.client;
  });

  const config = getClientConfig(client);

  const { petSection } = config;

  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setWithPets(null));
    dispatch(setGuests(null));
    dispatch(setWhoIsStaying(null));
    setIsDeleted(true);
    if (!petSection) {
      dispatch(setWithPets(false));
    }
  }, [petSection]); // eslint-disable-line

  useEffect(() => {
    if (withPets !== null && guestCount !== null && isDeleted) {
      navigate('/budget');
    }
  }, [withPets, guestCount, navigate, isDeleted]);

  const handleChangeWithPets = (value: boolean) => {
    dispatch(setWithPets(value));
  };

  const setGuestCount = (value: number, label: string) => {
    dispatch(setGuests(value));
    dispatch(setWhoIsStaying(label));
  };

  return (
    <AppLayout image="who.jpg" step={currentStep}>
      <div className="flex flex-col items-center w-full">
        <Heading className="second-font mb-10">{currentStep?.title}</Heading>
        <div
          className={cn(
            'flex gap-x-10 gap-y-5 w-full',
            currentStep?.contentClassName ?? 'flex-wrap justify-evenly '
          )}
        >
          <Button
            isActive={guestCount === 1}
            onClick={() => setGuestCount(1, 'just me')}
          >
            Just me
          </Button>
          <Button
            isActive={guestCount === 2}
            onClick={() => setGuestCount(2, 'me and partner')}
          >
            Me & my partner
          </Button>
          <Button
            isActive={guestCount === 3}
            onClick={() => setGuestCount(3, 'me and friend')}
          >
            Me & my friend
          </Button>
          <Button
            isActive={guestCount === 4}
            onClick={() => setGuestCount(4, 'more than 2')}
          >
            More than 2 of us
          </Button>
        </div>
      </div>
      {petSection ? (
        <div className="flex flex-col items-center w-full">
          <Heading className="second-font mb-6">Do you have pets?</Heading>
          <div className="flex flex-wrap justify-evenly w-full">
            <Button
              className="mb-5"
              isActive={withPets === true}
              onClick={() => handleChangeWithPets(true)}
            >
              Yes
            </Button>
            <Button
              className="mb-5"
              isActive={withPets === false}
              onClick={() => handleChangeWithPets(false)}
            >
              No
            </Button>
          </div>
          <div>Pets considered on application</div>
        </div>
      ) : null}
    </AppLayout>
  );
};

export default WhoPage;
