import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getClient } from "../../service/api";
import { AreaType } from "../../service/types";
import { RESET_ALL } from "../actions";

export interface WhereState {
  destinations: AreaType[] | any;
  chosenDestination: AreaType | null;
  loadingAllDestination: boolean;
  destinationId: number;
}

const initialState: WhereState = {
  destinations: [],
  chosenDestination: null,
  loadingAllDestination: false,
  destinationId: 0,
};

export const getAllDestinations = createAsyncThunk(
  "where/fetchAllDestinations",
  async (client: string | null) => {
    try {
      const apiClient = getClient(client);
      const resi = await apiClient.get("geography/area", {
        params: { pageSize: 10 },
        data: null,
      });
      const { data } = resi;
      const { results } = data;
      if (results) {
        return results.filter(
          (item: any) =>
            item.area !== "South London" && item.area !== "East London"
        );
      }
      throw new Error("No areas, returned");
    } catch (e) {
      return [];
    }
  }
);

export const whereSlice = createSlice({
  name: "where",
  initialState,
  reducers: {
    setDestination: (state: WhereState, action: PayloadAction<AreaType>) => {
      state.chosenDestination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDestinations.pending, (state) => {
      state.loadingAllDestination = true;
    });
    builder.addCase(getAllDestinations.fulfilled, (state, action) => {
      state.destinations = action.payload;
      state.loadingAllDestination = false;
    });
    builder.addCase(RESET_ALL, () => {
      return { ...initialState };
    });
  },
});

export const { setDestination } = whereSlice.actions;

export default whereSlice.reducer;
