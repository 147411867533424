import axios from "axios";

let baseUrl = "https://api-test.rerumapp.uk/api/v1/";
const tokens = {
  "gravity": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJncmF2aXR5Y29saXZpbmctYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2NDg1NDU2Mzd9.bockss4q2qehqxgNKfwRVXFiQhzOfyvuSWMVqNtXH-o",
  //"neybor": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtdWx0aWN1cnJlbmN5dGVzdDMtYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2OTUyMTQ4ODZ9.m5qqHtXE3b5uYUmeVuOupTfVyjaRyxm2P3Nixl78QLc",
  "neybor": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuZXlib3ItYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2OTUyMTQ4ODZ9.bFqt98Zkqs6I1PhKTPsWmTYn7LvhGW0vPx_4ogC4m1M",
  //"multicurrencytest3": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtdWx0aWN1cnJlbmN5dGVzdDMtYXBpIiwiZXhwIjoyMTE0MTU1MjU5LCJpYXQiOjE2OTUyMTQ4ODZ9.m5qqHtXE3b5uYUmeVuOupTfVyjaRyxm2P3Nixl78QLc",
};
const getClient = (client: string | null) => {
  // @ts-ignore
  const token = tokens[client] ? tokens[client] : tokens.gravity;
  return axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // @ts-ignore
      Authorization: token,
    },
  });
}

export { getClient };
