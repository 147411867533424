const gravitycoliving = require('./gravitycoliving');
const neybor = require('./neybor');

const { NODE_ENV } = process.env;

const getClientConfig = (cl) => {
  const deltas = { gravitycoliving, neybor }[cl];
  return NODE_ENV === 'development' ? deltas.test : deltas.prod;
}

export { getClientConfig };
