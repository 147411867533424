import { FC } from 'react';
import { buildingMap } from '../../../service/utils';

interface OfferCardLinkButtonProps {
  isAfter: boolean;
  building: string;
  offerId: string;
  rateIdentifier: string;
  callback: (id: string, rateIdentifier: string) => void; // eslint-disable-line
  client: string;
}

const OfferCardLinkButton: FC<OfferCardLinkButtonProps> = ({
  isAfter,
  building,
  offerId,
  rateIdentifier,
  callback,
  client,
}) => {
  const url = buildingMap[client][building.toLowerCase()];
  if (url === undefined) {
    return (
      <button
        className="bg-gravity font-bold rounded-md p-2 text-base text-white w-full hover:opacity-80"
        onClick={() => callback(offerId, rateIdentifier)}
        type="button"
      >
        Book a viewing!
      </button>
    );
  } else {
    if (isAfter) {
      return (
        <a href={url}>
          <button
            type="button"
            className="bg-gravity font-bold rounded-md p-2 text-base text-white w-full hover:opacity-80"
          >
            Register your interest!
          </button>
        </a>
      );
    }
    return (
      <button
        className="bg-gravity font-bold rounded-md p-2 text-base text-white w-full hover:opacity-80 place-self-end"
        onClick={() => callback(offerId, rateIdentifier)}
        type="button"
      >
        Book a viewing!
      </button>
    );
  }
};

export default OfferCardLinkButton;
