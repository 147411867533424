import Heading from '../../ui/Heading';
import AppLayout from '../../ui/AppLayout';

const Done = (props: any) => {
  // eslint-disable-line
  console.log(props);

  return (
    <AppLayout image="where.jpg">
      <Heading className="mb-10">Done</Heading>
      <div className="flex flex-col items-center w-full">
        <h1>You are all set</h1>
      </div>
    </AppLayout>
  );
};

export default Done;
