import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import {resetAll, setClient, setRootEl} from './features/actions';
const { REACT_APP_TENANT_ID, NODE_ENV } = process.env;

if (NODE_ENV === 'development') {
  // @ts-ignore
  store.dispatch(setClient(REACT_APP_TENANT_ID));
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  class EnquiryTool {
    el: string | null = null;
    client: string | null = null;

    constructor(id: string, client?: string) {
      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA
      serviceWorker.unregister();
      this.el = id;
      this.client = client ? client : 'gravity';
      if (this.el !== null && this.client !== null) {
        store.dispatch(setRootEl(this.el));
        store.dispatch(setClient(this.client));
      }
    }

    start() {
      if (this.el !== null && this.client !== null) {
        store.dispatch(setRootEl(this.el));
        store.dispatch(setClient(this.client));
        ReactDOM.render(
          <React.StrictMode>
            <HashRouter>
              <Provider store={store}>
                <App />
              </Provider>
            </HashRouter>
          </React.StrictMode>,
          document.getElementById(this.el)
        );
      } else {
        console.log('The ID or CLIENT was not set.');
      }
    }

    destroy() {
      if (this.el !== null) {
        store.dispatch(resetAll());
        const element = document.getElementById(this.el);
        // @ts-ignore
        ReactDOM.unmountComponentAtNode(element);
      }
    }

    getClient() {
      return this.client;
    }
  }

  // @ts-ignore
  const global = window || global;
  global.EnquiryTool = EnquiryTool;
}
