import React, { FC } from 'react';

interface FullOrPartialLayoutProps {
  image: string;
  client: string | null;
  fullBackgroundImage: boolean;
}

const FullOrPartialLayout: FC<FullOrPartialLayoutProps> = ({
  image,
  client,
  fullBackgroundImage,
}) => {
  let component = (
    <div className="hidden flex-1 lg:flex">
      <img
        className="brightness-75 object-cover rounded-l-lg h-full w-full"
        src={`${process.env.PUBLIC_URL}/route-images/${client}/${image}`}
        alt="step"
      />
    </div>
  );
  if (fullBackgroundImage) {
    component = (
      <img
        className="brightness-75 object-cover rounded-l-lg h-full w-full absolute top-0 left-0"
        src={`${process.env.PUBLIC_URL}/route-images/${client}/${image}`}
        alt="step"
      />
    );
  }

  return <React.Fragment>{component}</React.Fragment>;
};

export default FullOrPartialLayout;
