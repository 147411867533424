import { FC } from 'react';

interface OfferCardButtonProps {
  availableFrom: string | null;
}

const OfferCardButton: FC<OfferCardButtonProps> = ({ availableFrom }) => {
  return (
    <div className="mt-6 text-gray-500 text-sm text-right">
      {availableFrom ? `Available from ${availableFrom}` : 'Available now'}
    </div>
  );
};

export default OfferCardButton;
