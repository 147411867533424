import { FC } from 'react';
import OfferCard from '../OfferCard';

interface OffersLineProps {
  offers: object[];
  onChoose: (offerId: string | number, rateId: string) => void; // eslint-disable-line
}

const OfferLine: FC<OffersLineProps | null> = ({ offers, onChoose }) => {
  // eslint-disable-line
  if (offers?.length > 0) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 offer-line">
        {offers.map((offer, index) => (
          <OfferCard onChoose={onChoose} offer={offer} key={index} />
        ))}
      </div>
    );
  }
  return null;
};

export default OfferLine;
